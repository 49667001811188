import clsx from 'clsx';
import React, { useRef } from 'react';
import { useOverlayPosition, useTooltipTrigger } from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

import { DateTime } from '@ping/utils';

import { TooltipContext } from '../tooltip.context';

import style from './style.module.scss';

interface ITooltipRootProps extends Omit<IReactStately.TooltipTriggerProps, 'delay'>, ICustomizable {
  children: React.ReactNode;
  /**
   * The delay time for the tooltip to show up.
   * @default 1_000
   */
  openDelay?: number;
  /**
   * The delay time for the tooltip to close
   * @default 500
   */
  closeDelay?: number;
  /**
   * The placement of the element with respect to its anchor element.
   * @default 'top'
   */
  placement?: IReactAria.Placement;
  offset?: number;
}

export const TooltipRoot = (props: ITooltipRootProps) => {
  const state = useTooltipTriggerState({
    delay: props.openDelay ?? DateTime.seconds(1),
    closeDelay: props.closeDelay ?? DateTime.seconds(0.5),
    ...props,
  });
  const triggerRef = React.useRef<HTMLSpanElement>();
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, triggerRef);

  // Get tooltip positioning props relative to its trigger
  const overlayRef = useRef<HTMLDivElement>();
  const { overlayProps, placement } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: props.placement || 'top',
    isOpen: state.isOpen,
    shouldUpdatePosition: true,
    offset: props.offset ?? 14,
  });

  return (
    <span className={clsx(style['tooltip-root'], props.className)} role='tooltip'>
      <TooltipContext.Provider
        value={{ state, triggerRef, triggerProps, tooltipProps, overlayProps, overlayRef, placement }}
      >
        {props.children}
      </TooltipContext.Provider>
    </span>
  );
};
